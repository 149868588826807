<template>
  <div ref="body">
    <custom-header active="exh" :subActive="current"></custom-header>

    <div class="content-container ">
      
      <div class="banner-box">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/property.png" alt="知识产权保护计划">
      </div>

      <van-tabs v-model="activeName" color="#060211" @click="onClick">
        <!-- <van-tab title="38届" name="38" title-class="tab"></van-tab> -->
        <van-tab title="37届" name="37" title-class="tab"></van-tab>
        <van-tab title="36届" name="36" title-class="tab"></van-tab>
        <van-tab title="35届" name="35" title-class="tab"></van-tab>
      </van-tabs>
      
      <div class="list">
        <van-empty v-if="exhibitors.length === 0" description="" />
        <template v-else>
          <div class="flex flex-wrap">            
            <div class="exhibitor flex" v-for="(item, i) in exhibitors" :key="i">
              <div class="exhibitor-cover">
                <img :src="item.logo" alt="" class="fill">
              </div>
              <div class="intro-box flex flex-direction justify-between">
                <div class="name text-bold ">{{ item.companyName }}</div>
                <div class="">
                  <div v-if="item.positionAll" class="intro">展位号：{{item.positionAll}}</div>
                  <div class="intro">38届作品备案数量：<span class="text-bold text-color">{{item.customizeNumber || "更新中"}}</span></div>
                  <div class="intro">累计备案总数量：<span class="text-bold text-color1">{{item.customizeNumber + item.customizeNumber37 + item.customizeNumber36 + item.customizeNumber35 + item.customizeNumber34 || "更新中"}}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <el-pagination background layout="prev, pager, next" :hide-on-single-page="pages <= 1" :current-page="pageNo" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"></el-pagination>
          </div>
        </template>
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon, Empty, Tab, Tabs } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getOriginalList } from '@/api/index.js'

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'property',
      activeName: '37',
      pageNo: 1,
      pageSize: 18,
      exhibitors: [],
    }
  },
  created() {
    this.getOriginals();
  },
  methods: {
    onClick(name) {
      this.handleCurrentChange(1)
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getOriginals();
    },
    getOriginals(){
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        exhNo: this.activeName,
      };
      getOriginalList(params).then(res => {
        let {total, pages, records} = res.resultData
        this.exhibitors = records;
        this.total = total;
        this.pages = pages;
      })
    }
  },
}
</script>

<style scoped lang="less">
.text-color{
  color: #048dad;
}
.text-color1{
  color: #153371;
}

// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .banner-box {
      img {
        display: block;
        width: 375px;
        height: auto;
        border-radius: 4px;
      }
    }
    
    h1 {
      margin-bottom: 5px;
      font-size: 20px;
    }
    h2 {
      font-size: 14px;
    }
    h2 + h2 {
      margin-bottom: 10px;
    }
    
    /deep/ .van-tabs {
      width: 350px;
      margin: 20px auto 0;
      
      .van-tabs--line .van-tabs__wrap {
        height: 30px;
      }
      
      .tab {
        font-size: 14px;
        padding: 0 10px;
      }
      
      .van-tabs__line {
        bottom: 22px;
        width: 32px;
        height: 3px;
      }
    }
    
    
    .list {
      width: 350px;
      margin: 15px auto 0;
    }
    
    .exhibitor {
      width: 170px;
      margin: 0 10px 12px 0;
      padding-bottom: 10px;
      border-radius: 4px;
      
      &:nth-child(2n) {
        margin-right: 0;
      }
      
      .exhibitor-cover {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        overflow: hidden;
      }
      
      .intro-box {
        width: 140px;
      }
      .name {
        width: 100%;
        padding: 0 5px 5px;
        font-size: 10px;
        box-sizing: border-box;
      }
      
      .intro {
        font-size: 10px;
        padding: 0 5px;
        margin-bottom: 5px;
      }
      
    }
    
  }
  
}

@media screen and (min-width: 1024px) {
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 0 auto 20px;
    
    .banner-box {
      img {
        display: block;
        width: 280px;
        height: auto;
        border-radius: 1px;
      }
    }
    
    h1 {
      margin-bottom: 5px;
      font-size: 8px;
    }
    h2 {
      font-size: 5px;
    }
    h2 + h2 {
      margin-bottom: 10px;
    }
    
    /deep/ .van-tabs--line .van-tabs__wrap {
        height: 20px;
    }
    
    /deep/ .tab {
      font-size: 6px;
      padding: 0 10px;
      line-height: 6px;
    }
    
    /deep/ .van-tabs__line {
      bottom: 0.45rem;
      width: 0.5rem;
      height: 0.05rem;
      
    }
    
    
    .list {
      margin-top: 15px;
      
      /deep/ .van-empty__image {
        width: 40%;
        height: auto;
      }
    }
    
    .exhibitor {
      width: 90px;
      margin: 0 5px 12px 0;
      padding-bottom: 2px;
      border-radius: 1px;
      
      &:nth-child(3n) {
        margin-right: 0;
      }
      
      .exhibitor-cover {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 2px;
        overflow: hidden;
      }
      
      .name {
        width: 60px;
        padding: 0 2px 2px;
        font-size: 3.4px;
        box-sizing: border-box;
      }
      
      .intro {
        font-size: 3.2px;
        padding: 0 2px;
        margin-bottom: 1px;
      }
      
    }
    
    /deep/ .el-pagination.is-background {
      
      .btn-next .el-icon, 
      .btn-prev .el-icon {
        font-size: 3.6px;
        font-weight: 700;
        height: 8px;
        line-height: 8px;
      }
      
      .btn-next,
      .btn-prev, 
      .el-pager li {
        margin: 0 1px;
        color: #606266;
        min-width: 8px;
        border-radius: 1px;
        padding: 0 1px;
        font-size: 3.6px;
        height: 8px;
        line-height: 8px;
      }
      
      .el-pager li:not(.disabled).active {
        background-color: #409EFF;
        color: #FFF;
      }
      
      .el-pager .more::before {
        line-height: 8px;
      }
    }
    
  }
  
}
  
</style>
